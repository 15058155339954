.html, .container-custom, body {
  background-color: #E4EBED!important;
}

.page-item.active .page-link {
  background-color: #0078ab !important;
  border-color: #0078ab !important;
  color: #fff !important;
}

.page-link {
  color: #0078ab !important;
}

.page-link:hover {
  cursor: pointer;
}

.small-text-margin {
  margin-left: 30px;
}

.jurisdiction-title {
  font-size: 21px;
  color: #333;
}

.btn {
  padding: 10px 20px;
  background-color: #538d9c !important;
  border-color: #538d9c !important;
  color: #ffffff !important;
}
.btn:hover {
  color: #ffffff;
}
